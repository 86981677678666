// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-ads-js": () => import("./../../../src/pages/about-our-ads.js" /* webpackChunkName: "component---src-pages-about-our-ads-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-buffalo-style-plant-based-bites-js": () => import("./../../../src/pages/products/buffalo-style-plant-based-bites.js" /* webpackChunkName: "component---src-pages-products-buffalo-style-plant-based-bites-js" */),
  "component---src-pages-products-crispy-plant-based-fillets-js": () => import("./../../../src/pages/products/crispy-plant-based-fillets.js" /* webpackChunkName: "component---src-pages-products-crispy-plant-based-fillets-js" */),
  "component---src-pages-products-grilled-plant-based-fillets-js": () => import("./../../../src/pages/products/grilled-plant-based-fillets.js" /* webpackChunkName: "component---src-pages-products-grilled-plant-based-fillets-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-plant-based-bratwurst-js": () => import("./../../../src/pages/products/plant-based-bratwurst.js" /* webpackChunkName: "component---src-pages-products-plant-based-bratwurst-js" */),
  "component---src-pages-products-plant-based-burger-js": () => import("./../../../src/pages/products/plant-based-burger.js" /* webpackChunkName: "component---src-pages-products-plant-based-burger-js" */),
  "component---src-pages-products-plant-based-ground-beef-js": () => import("./../../../src/pages/products/plant-based-ground-beef.js" /* webpackChunkName: "component---src-pages-products-plant-based-ground-beef-js" */),
  "component---src-pages-products-plant-based-nuggets-js": () => import("./../../../src/pages/products/plant-based-nuggets.js" /* webpackChunkName: "component---src-pages-products-plant-based-nuggets-js" */),
  "component---src-pages-products-spicy-plant-based-nuggets-js": () => import("./../../../src/pages/products/spicy-plant-based-nuggets.js" /* webpackChunkName: "component---src-pages-products-spicy-plant-based-nuggets-js" */),
  "component---src-pages-products-sweet-barbecue-plant-based-bites-js": () => import("./../../../src/pages/products/sweet-barbecue-plant-based-bites.js" /* webpackChunkName: "component---src-pages-products-sweet-barbecue-plant-based-bites-js" */),
  "component---src-pages-products-whole-grain-plant-based-tenders-js": () => import("./../../../src/pages/products/whole-grain-plant-based-tenders.js" /* webpackChunkName: "component---src-pages-products-whole-grain-plant-based-tenders-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */)
}

